/**
 * Created by Administrator on 2017/7/4.
 */


'use strict';
angular.module('controllers').controller('descriptionCtr', ['$scope', 'Upload', '$http', '$window', '$timeout','$state',
  function ($scope, Upload, $http, $window, $timeout,$state) {

    var loadTable = function (data, reload) {
      if (reload) {
        $('#table2').bootstrapTable('load', data);
      } else {
        $('#table2').bootstrapTable({
          pagination: true,
          pageSize: 10, //每页显示10条
          cache: false,
          search: true,
          showColumns: 'true',
          showExport: true,//显示导出按钮
          exportDataType: 'all',//导出类型
          columns: [
          {
            title: '操作',
            align: 'center',
            formatter: function (value, row, index) {
              row = angular.toJson(row);
              return '<button style="width:80px;" class=\'btn btn-success btn-xs\' onclick=\'angular.element(this).scope().toUpdate(' + row + ')\'>编辑</button>'
              +'<button style="margin-top:10px;width:80px;" class=\'btn btn-danger btn-xs\' onclick=\'angular.element(this).scope().showDeleteModal(' + row + ')\'>删除</button>';
            }
          },{
            title: 'device_name',
            align: 'center',
            field: 'device_name'
          }, {
            title: 'device_name_cn',
            align: 'center',
            field: 'device_name_cn'
          }, {
            title: 'device_type',
            align: 'center',
            field: 'device_type'
          }, {
            title: 'protocol_type',
            align: 'center',
            field: 'protocol_type'
          }, {
            title: 'device_icon',
            align: 'center',
            field: 'device_icon',
            formatter:function(value,row,index){
              var s = row.device_icon?'<a class = "view"  href="javascript:void(0)"><img style="width:70;height:30px;"  src="'+row.device_icon+'" /></a>':'无';
              return s;
            }
          }, {
            title: 'offline_icon',
            align: 'center',
            field: 'offline_icon',
            formatter:function(value,row,index){
              var s = row.offline_icon?'<a class = "view"  href="javascript:void(0)"><img style="width:70;height:30px;"  src="'+row.offline_icon+'" /></a>':'无';
              return s;
            }
          }, {
            title: 'device_reset_diagram',
            align: 'center',
            field: 'device_reset_diagram',
            formatter:function(value,row,index){
              var s = row.device_reset_diagram?'<a class = "view"  href="javascript:void(0)"><img style="width:70;height:30px;"  src="'+row.device_reset_diagram+'" /></a>':'无';
              return s;
            }
          }, {
            title: 'device_desc',
            align: 'center',
            field: 'device_desc'
          }, {
            title: 'device_setup_desc',
            align: 'center',
            field: 'device_setup_desc'
          },{
            title: 'manufacturer_name',
            align: 'center',
            field: 'manufacturer_name'
          }
          ],
          data: data
        });
      }
    };
    $scope.showDeleteModal = function (data) {
      $scope.toDelData =data;
      console.log(data);
      $timeout(function () {
        $('#closePageWin').modal('show')
      }, 10);
    };
    $scope.toUpdate = function(data){
      $state.go('main.uploadDescription',{itemId:data._id});
    }
    $scope.toDelete = function(){
      $http({
        method: 'POST',
        url: '/web/description/delete',
        data:{data : $scope.toDelData}
      }).then(function (response) {
        $timeout(function () {
          $('#closePageWin').modal('hide')
        }, 10);
        if (response.data.code == 0) {
          $scope.$emit('notification', {type: 'success', message: '删除成功'});
          $scope.deviceInit(true);
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    }
    $scope.deviceInit = function (reload) {
      $http({
        method: 'GET',
        url: '/web/description/getAll',
      }).then(function (response) {
        if (response.data.code == 0) {
          loadTable(response.data.data, reload)
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };


  }
]);