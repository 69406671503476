/**
 * I'm the King of the World!
 */
angular.module('productionTest', [
  'ngAnimate',
  'ipCookie',
  'ui.router',
  'ngFileUpload',
  'controllers',
  'services',
  'directives',
  'views'
])
  .constant('Global', {})
  .config(['$stateProvider', '$urlRouterProvider', '$locationProvider', '$httpProvider',
    function ($stateProvider, $urlRouterProvider, $locationProvider, $httpProvider) {
      'use strict';

      // 修改默认请求头
      $httpProvider.defaults.headers.common = {'content-type': 'application/json;charset=utf-8'};

      // 拦截器，在请求头自动加上flag
      $httpProvider.interceptors.push('FlagInterceptor');

      // 开启 HTML5 模式
      $locationProvider.html5Mode(true);

      // 将所有未匹配路由转至根目录
      $urlRouterProvider.otherwise(function ($injector) {
        $injector.get('$state').go('login');
      });

      // 路由
      $stateProvider
      //登录
        .state('login', {
          url: '^/main/login',
          controller: 'loginCtr',
          templateProvider: ['$templateCache', function ($templateCache) {
            return $templateCache.get('/login.view.html');
          }]
        })

        //一级页面，头部与菜单栏部分
        .state('main', {
          url: '^/main',
          controller: 'mainCtr',
          templateProvider: ['$templateCache', function ($templateCache) {
            return $templateCache.get('/main.view.html');
          }],
        })

        //用户管理
        .state('main.user', {
          url: '^/main/user',
          controller: 'userCtr',
          templateProvider: ['$templateCache', function ($templateCache) {
            return $templateCache.get('/user.view.html');
          }],
        })

        //统计中心
        .state('main.statistic', {
          url: '^/main/statistic',
          controller: 'statisticCtr',
          templateProvider: ['$templateCache', function ($templateCache) {
            return $templateCache.get('/statistic.view.html');
          }],
        })

        //设备管理
        .state('main.device', {
          url: '^/main/device',
          controller: 'deviceCtr',
          templateProvider: ['$templateCache', function ($templateCache) {
            return $templateCache.get('/device.view.html');
          }],
        })

        //APK管理
        .state('main.apk', {
          url: '^/main/apk',
          controller: 'apkCtr',
          templateProvider: ['$templateCache', function ($templateCache) {
            return $templateCache.get('/apk.view.html');
          }],
        })

        //APK上传
        .state('main.uploadAPK', {
          url: '^/main/uploadAPK',
          params:{type:null},
          controller: 'uploadAPKCtr',
          templateProvider: ['$templateCache', function ($templateCache) {
            return $templateCache.get('/uploadAPK.view.html');
          }],
        })
        .state('main.description',{
          url: '^/main/description',
          params:{type:null},
          controller: 'descriptionCtr',
          templateProvider: ['$templateCache', function ($templateCache) {
            return $templateCache.get('/description.view.html');
          }],
        })
        //语音命令词
        .state('main.speeker', {
          url: '^/main/speeker',
          controller: 'speekerCtr',
          templateProvider: ['$templateCache', function ($templateCache) {
            return $templateCache.get('/speeker.view.html');
          }],
        })
        .state('main.uploadDescription', {
          url: '^/main/uploadDescription/{itemId}',
          controller: 'uploadDescriptionCtr',
          templateProvider: ['$templateCache', function ($templateCache) {
            return $templateCache.get('/uploadDescription.view.html');
          }],
        })

    }
  ])
;

//  .run(['checkSignIn', '$templateCache',function (checkSignIn) {
//    // 检查用户是否登录
//    checkSignIn();
//
//}]);

/**
 * 创建 Controllers, Services, Directives, Filters 模块
 */
angular.module('controllers', []);
angular.module('services', []);
angular.module('directives', []);
angular.module('views', []);