/**
 * Created by Administrator on 2017/7/4.
 */

'use strict';
angular.module('controllers').controller('statisticCtr', ['$scope', '$window','$http',
  function ($scope,$window,$http) {
    var socket = io('/socketRoom',{ "transports":['websocket', 'polling']});

    $scope.data={
      device:0,
      newUser: 0,
      active: 0,
      active7Day: 0
    };
    socket.on('connect', function () {
      console.log('socket.io is connected');
    });

    socket.on('deviceCount',function(msg){
      console.log(msg);
      $scope.data.device = msg;
      $scope.$apply();
    });

    var dom = document.getElementById("container");
    var myChart = echarts.init(dom);
    var option;
    $scope.statisticsInit = function () {
      $http({
        method: 'GET',
        url: '/web/statistic/getAll',
      }).then(function (response) {
        if (response.data.code == 0) {
          $scope.data.newUser = response.data.data.newUser;
          $scope.data.active = response.data.data.active;
          $scope.data.active7Day = response.data.data.count;

          option ={
            title: {
              text: '活跃人数',//主标题
            },
            tooltip: {
              trigger: 'axis',
              show: true
            },
            grid: {
              left: '6%',
              right: '6%',
              bottom: '8%',// 这几个属性可以控制图表上下左右的空白尺寸，可以自己试试。
              containLabel: true
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
//      axisLabel: {rotate: 20}, //旋转
              data:response.data.data.day
            },
            yAxis: {
              type: 'value',
              scale:true
//      minInterval: 20
            },
            series: [{
              name: '人数',
              type: 'line',
              // smooth: true,
              data: response.data.data.arr,
              label:{show:true,color:'blue'},
              areaStyle: {color:'#33ff66'},
              lineStyle:{color:'#0099ff'}
            }]
          };
          if (option && typeof option === "object") {
            myChart.setOption(option, true);
            myChart.dispatchAction({type: 'showTip', seriesIndex: '0', dataIndex: '0'});
          }

        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };

    $(window).on('resize', function () {
      dom.style.width = $('.panel-body').width()+'px';
      myChart.resize();
    });

  }
]);