/**
 * Created by Administrator on 2017/7/12.
 */

'use strict';
angular.module('controllers').controller('uploadAPKCtr', ['$scope', 'Upload', '$http', '$window', '$timeout','$stateParams',
  function ($scope, Upload, $http, $window, $timeout,$stateParams) {

    $scope.newApk = {
      name: '',
      type: $stateParams.type||'1',
      version: '',
      md5:'',
      content:'',
      file: ''
    };

    $scope.uploadApk = function (file) {
      if (!file) {
        return;
      }
      var check = new RegExp("w*.apk$", 'i').test(file.name);
      if (!check) {
        $scope.$emit('notification', {type: 'danger', message: '上传文件不是apk格式'});
        return
      }
      if (file.name)
        $scope.newApk.file = file;
    };

    //提交数据
    $scope.submitData = function () {

      if (!$scope.newApk.name) {
        $scope.$emit('notification', {type: 'danger', message: '请填写版本名称'});
        return;
      }

      if (!$scope.newApk.version) {
        $scope.$emit('notification', {type: 'danger', message: '请填写版本号'});
        return;
      }

      var versionArr = $scope.newApk.version.split('.');
      if(versionArr.length!==3){
        $scope.$emit('notification', {type: 'danger', message: '版本号格式应该为X.X.X格式'});
        return;
      }
      var checkVer = false;
      for(var i=0;i<versionArr.length;i++){
        if(versionArr[i]===''){
          checkVer = true;
          break;
        }
        var one = Number(versionArr[i]);
        if(!(typeof one === 'number' && !isNaN(one))) {
          checkVer = true;
          break;
        }
      }

      if (!$scope.newApk.md5) {
        $scope.$emit('notification', {type: 'danger', message: '请填写md5'});
        return;
      }

      if(checkVer){
        $scope.$emit('notification', {type: 'danger', message: '版本号只能存在数字'});
        return;
      }

      if (!$scope.newApk.content) {
        $scope.$emit('notification', {type: 'danger', message: '请填写更新内容'});
        return;
      }

      if (!$scope.newApk.file) {
        $scope.$emit('notification', {type: 'danger', message: '请选择上传的APK文件'});
        return;
      }

      Upload.upload({
        method: 'POST',
        url: '/web/apk/uploadApk',
        data: $scope.newApk
      }).then(function (result) {
        if (result.data.code == 0) {
          $scope.newApk = {
            name: '',
            type: '1',
            version: '',
            content:'',
            file: ''
          };
          $scope.$emit('notification', {type: 'success', message: '上传成功'});
        } else {
          $scope.$emit('notification', {type: 'danger', message: result.data.msg});
        }

      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误,提交失败'});
      });
    };

    $scope.iosSubmitData = function(){
      if (!$scope.newApk.name) {
        $scope.$emit('notification', {type: 'danger', message: '请填写版本名称'});
        return;
      }

      if (!$scope.newApk.version) {
        $scope.$emit('notification', {type: 'danger', message: '请填写版本号'});
        return;
      }

      var iosVersionArr = $scope.newApk.version.split('.');
      if(iosVersionArr.length!==3){
        $scope.$emit('notification', {type: 'danger', message: '版本号格式应该为X.X.X格式'});
        return;
      }
      var checkVer = false;
      for(var i=0;i<iosVersionArr.length;i++){
        if(iosVersionArr[i]===''){
          checkVer = true;
          break;
        }
        var one = Number(iosVersionArr[i]);
        if(!(typeof one === 'number' && !isNaN(one))) {
          checkVer = true;
          break;
        }
      }

      if(checkVer){
        $scope.$emit('notification', {type: 'danger', message: '版本号只能存在数字'});
        return;
      }

      if (!$scope.newApk.content) {
        $scope.$emit('notification', {type: 'danger', message: '请填写更新内容'});
        return;
      }

      $http({
        method: 'POST',
        url: '/web/apk/iosVersion',
        data: $scope.newApk
      }).then(function (response) {
          if (response.data.code == 0) {
            $scope.newApk = {
              name: '',
              type: '2',
              version: '',
              content:'',
              file: ''
            };
            $scope.$emit('notification', {type: 'success', message: '上传成功'});
          } else {
            $scope.$emit('notification', {type: 'danger', message: response.data.msg});
          }

        }, function (err) {
          $scope.$emit('notification', {type: 'danger', message: '网络错误,提交失败'});
        });
    }
  }
]);