/**
 * Created by Administrator on 2017/7/11.
 */


'use strict';
angular.module('controllers').controller('apkCtr', ['$scope', 'Upload', '$http', '$window', '$timeout',
  function ($scope, Upload, $http, $window, $timeout) {

    $scope.apk = {
      name: '',
      type: 1,
      gatewayQR: '/QRcode/gateway.png',
      routerQR: '/QRcode/router.png'
    };


    var loadTable = function (data, type, reload) {
      if (reload) {
        $('#table' + type).bootstrapTable('load', data);
      } else {
        $('#table' + type).bootstrapTable({
          pagination: true,
          pageSize: 10, //每页显示10条
          cache: false,
          search: true,
          showColumns: 'true',
          showExport: true,//显示导出按钮
          exportDataType: 'all',//导出类型
          columns: [{
            title: 'APK名称',
            align: 'center',
            field: 'name',
            sortable: true,
          }, {
            title: '版本',
            align: 'center',
            field: 'version',
          }, {
            title: 'md5码',
            align: 'center',
            field: 'md5',
          }, {
            title: '更新内容',
            align: 'center',
            field: 'content',
            formatter: function (value, row, index) {
              value = value||'';
              return  value.replace(/[^\x00-\xff]/g,"$&\x01").replace(/.{25}\x01?/g,"$&\n").replace(/\x01/g,"");
            }
          }],
          data: data
        });
      }
    };

    //获取数据
    $scope.getData = function (type, reload) {
      $http({
        method: 'GET',
        url: '/web/apk/getAll',
        params: {
          type: type
        }
      }).then(function (response) {
        if (response.data.code == 0) {
          loadTable(response.data.data, type, reload)
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };

    //页面初始化获取数据
    $scope.init = function () {
      $scope.getData(1);
      $scope.getData(2);
    };

    //打开删除确认模态窗
    $scope.delApkModal = function (name, type) {
      $scope.apk.name = name;
      $scope.apk.type = type;
      $timeout(function () {
        $('#delApkModal').modal('show');
      }, 10);
    };

    //删除确认
    $scope.doDel = function () {
      $http({
        method: 'DELETE',
        url: '/web/apk/delApk',
        params: {name: $scope.apk.name}
      }).then(function (response) {
        $timeout(function () {
          $('#delApkModal').modal('hide')
        }, 10);

        if (response.data.code == 0) {
          $scope.getData($scope.apk.type, true);
          $scope.$emit('notification', {type: 'success', message: '删除成功'});
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };

    //打开启动模态窗
    $scope.openModal = function (name, type) {
      $scope.apk.name = name;
      $scope.apk.type = type;
      $timeout(function () {
        $('#openModal').modal('show');
      }, 10);
    };

    //启动确认
    $scope.doOpen = function () {
      $http({
        method: 'POST',
        url: '/web/apk/openApk',
        data: {
          name: $scope.apk.name,
          type: $scope.apk.type
        }
      }).then(function (response) {
        $timeout(function () {
          $('#openModal').modal('hide')
        }, 10);

        if (response.data.code == 0) {
          $scope.getData($scope.apk.type, true);
          $scope.$emit('notification', {type: 'success', message: '启动成功'});
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };

    //打开关闭确认模态窗
    $scope.closeModal = function (name, type) {
      $scope.apk.name = name;
      $scope.apk.type = type;
      $timeout(function () {
        $('#closeModal').modal('show')
      }, 10);

    };

    $scope.doClose = function () {
      $http({
        method: 'POST',
        url: '/web/apk/closeApk',
        data: {
          name: $scope.apk.name,
          type: $scope.apk.type
        }
      }).then(function (response) {
        $timeout(function () {
          $('#closeModal').modal('hide')
        }, 10);

        if (response.data.code == 0) {
          $scope.getData($scope.apk.type, true);
          $scope.$emit('notification', {type: 'success', message: '停用成功'});
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };

  }
]);