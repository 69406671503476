/**
 * Created by Administrator on 2017/7/3.
 */
/**
 * Created by Administrator on 2017/5/17.
 */
'use strict';
angular.module('controllers').controller('userCtr', ['$scope', '$http', '$timeout',
  function ($scope, $http, $timeout) {

    $scope.user = {
      children: [],
      scene: [],
      phone: ''
    };

    var loadTable = function (reload) {
      if (reload) {
        $('#table2').bootstrapTable('refresh');
      } else {
        $('#table2').bootstrapTable({
          method : 'get',
          url : "/web/account/getAll",//请求路径
          striped : true, //是否显示行间隔色
          pagination : true,//是否分页
          sidePagination : 'server',//server:服务器端分页|client：前端分页
          pageSize : 10,//单页记录数
          pageList : [ 5, 10, 20, 30 ],//可选择单页记录数
          showRefresh : true,//刷新按钮
          cache: false,
          search: true,
          showColumns: 'true',
          showExport: true,//显示导出按钮
          exportDataType: 'all',//导出类型
          queryParams : function(params) {//上传服务器的参数
            console.log(params);
            var temp = {//如果是在服务器端实现分页，limit、offset这两个参数是必须的
              limit : params.limit, // 每页显示数量
              page : (params.offset / params.limit) + 1, //当前页码 
              searchName : $('.search input').val(),
              order : params.order, 
              sort : params.sort,
            };
            return temp;
          },
          columns: [{
            title: '账号',
            align: 'center',
            field: 'account',
            sortable: true
          },{
            title: '手机号',
            align: 'center',
            field: 'phone',
            sortable: true
          }, {
            title: '昵称',
            align: 'center',
            field: 'nickname',
          }, {
            title: '注册时间',
            align: 'center',
            sortable: true,
            field: 'created_at',
            formatter: function (value, row, index) {
              return moment(value).format('YYYY-MM-DD HH:mm:ss');
            }
          }, {
            title: '绑定家庭',
            align: 'center',
            formatter: function (value, row, index) {
              var children = angular.toJson(row.family);
              return '<a href=\'#\' class=\'a_button\' onclick=\'angular.element(this).scope().openChildrenModal(' + children + ')\'>点击查看</a>';
            }
          }, {
            title: '操作',
            align: 'center',
            formatter: function (value, row, index) {
              return '<button  class=\'btn btn-default btn-xs\' onclick=\'angular.element(this).scope().resetPwdModal("' + row.phone + '")\'>重置密码</button>&nbsp;'
                // +'&nbsp;<button  class=\'btn btn-default btn-xs\' onclick=\'angular.element(this).scope().delAccountModal("' + row.phone + '")\'>删除账号</button>';
            }
          }]
        });
      }
    };

    $scope.userInit = function (reload) {
      loadTable(reload)
    };

    $scope.resetPwdModal = function (phone) {
      $scope.user.phone = phone;
      $timeout(function () {
        $('#resetModal').modal('show')
      }, 10);
    };

    $scope.doResetPwd = function () {
      $http({
        method: 'PUT',
        url: '/web/account/resetPwd',
        data: {
          phone: $scope.user.phone
        }
      }).then(function (response) {
        $timeout(function () {
          $('#resetModal').modal('hide')
        }, 10);

        if (response.data.code == 0) {
          $scope.$emit('notification', {type: 'success', message: '重置成功'});
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };

    $scope.delAccountModal = function (phone) {
      $scope.user.phone = phone;
      $timeout(function () {
        $('#delModal').modal('show')
      }, 10);
    };

    $scope.doDelAccount = function () {
      $http({
        method: 'DELETE',
        url: '/web/account/delAccount',
        params: {
          phone: $scope.user.phone
        }
      }).then(function (response) {
        $timeout(function () {
          $('#delModal').modal('hide')
        }, 10);

        if (response.data.code == 0) {
          $scope.userInit(true);
          $scope.$emit('notification', {type: 'success', message: '删除成功'});
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };


    $scope.openChildrenModal = function (children) {
      var newFamily = [];
      for (var i = 0; i < children.length; i++) {
        if (children[i].gateway.length === 0) {
          var one = {
            familyName: children[i].name,
            rowspan: 1,
            isAdmin: children[i].isAdmin==9?'超级管理员':children[i].isAdmin==1?'管理员':'普通成员',
            name: '未绑定网关',
            mac: '未绑定网关',
          };
          newFamily.push(one);
          continue;
        }
        for (var k = 0; k < children[i].gateway.length; k++) {
          var one = {
            name: children[i].gateway[k].name,
            mac: children[i].gateway[k].mac
          };
          if (k === 0){
            one.familyName = children[i].name;
            one.rowspan = children[i].gateway.length;
            one.isAdmin=children[i].isAdmin==9?'超级管理员':children[i].isAdmin==1?'管理员':'普通成员'
          }
          newFamily.push(one);
        }
      }
      $scope.user.family = newFamily;
      $timeout(function () {
        $('#childrenModal').modal('show');
      }, 10);
    };


  }
]);