/**
 * Created by Administrator on 2017/5/24.
 */
angular.module('controllers')
  .controller('mainCtr', ['$scope', '$http', '$state',
    function ($scope, $http, $state) {
      'use strict';

      $scope.Arr = ['日', '一', '二', '三', '四', '五', '六'];
      $scope.dt = new Date();
      $scope.weekIndex = $scope.dt.getDay();
      $scope.week = '星期' + $scope.Arr[$scope.weekIndex];


      $scope.doLoginOut = function(){
        $http({
          method: 'POST',
          url: '/web/user/loginOut',
        }).then(function (response) {
          console.log(response)
          if (response.data.code == 0) {
            $state.go('login');
          } else {
            $scope.$emit('notification', {type: 'danger', message: response.data.msg});
          }
        }, function (err) {
          $scope.$emit('notification', {type: 'danger', message: '网络错误,登出失败'});
        });
      }

      $(window).on('resize', function () {
        if ($(window).width() > 768){
          $('#sidebar-collapse').collapse('show')

        }
      });
      $(window).on('resize', function () {
        if ($(window).width() <= 767){
          $('#sidebar-collapse').collapse('hide')
        }
      });


    }
  ]);