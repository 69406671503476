/**
 * Created by Administrator on 2017/7/12.
 */

'use strict';

angular.module('controllers').controller('uploadDescriptionCtr', ['$scope', 'Upload', '$http', '$window', '$timeout', '$state', '$stateParams',
  function ($scope, Upload, $http, $window, $timeout, $state, $stateParams) {
    $scope.deviceTypeSub = {
      typeCn:null,
      typeEn:null,
    };

    $scope.deviceTypeList = []

    $scope.uploadFiles = {
      device_icon:null,
      offline_icon:null,
      device_reset_diagram:null
    };
    $scope.myEndpoints = []
    $scope.addEndPoint = function(){
      $scope.myEndpoints.push({});
    }
    $scope.delEndPoint = function(index){
      $scope.myEndpoints.splice(index,1);
    }
    $scope.init = function () {
      console.log('init');
      $http({
        method: 'GET',
        url: '/config/getWebConfig',
      }).then(function (response) {
          if (response.data.code === 0) {
              $scope.domain = response.data.domain;
              console.log($scope.domain);
          }
      }, function (err) {
          $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      })
      $http({
        method: 'GET',
        url: '/web/description/getDeticeType',
      }).then(function (response) {
          if (response.data.code === 0) {
            console.log(response.data.data);
            $scope.deviceTypeList = response.data.data;
          }
      }, function (err) {
          $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      })
      $scope.description = {
        device_name: '',
        device_name_cn: '',
        device_type: '',
        protocol_type: 'zigbee',
        device_icon:'',
        offline_icon:'',
        device_reset_diagram:'',
        device_desc:'',
        device_setup_desc:'',
        manufacturer_name:'',
        endpoints:[]
      }
      if($stateParams.itemId){
        $http({
          method: 'Post',
          url: '/web/description/findDescription',
          data:{data:{_id:$stateParams.itemId}},
        }).then(function (response) {
            if (response.data.code == 0) {
              $scope.description = response.data.data;
              if($scope.description.device_type_sub){
                $scope.deviceTypeSub.typeEn = $scope.description.device_type_sub.typeEn;
              }

              $scope.description.endpoints.forEach(element_endpoints => {
                let endpoint_id = element_endpoints.endpoint_id;
                element_endpoints.value_list.forEach(element_value_list => {
                  $scope.myEndpoints.push({
                    key:endpoint_id,
                    value:element_value_list.value_name
                  })
                });
              });
              $scope.description.endpoints = [];
            }
        }, function (err) {
            $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
        })
      }
    }
    $scope.uploadData =function(){
      if (/[']/.test($scope.description.device_name)
      ||/[']/.test($scope.description.device_name_cn)
      ||/[']/.test($scope.description.device_type)
      ||/[']/.test($scope.description.protocol_type)
      ||/[']/.test($scope.description.device_desc)
      ||/[']/.test($scope.description.device_setup_desc)
      ||/[']/.test($scope.description.manufacturer_name)
      ) {
        $scope.$emit('notification', {type: 'danger', message: '内容包含英文的单引号，不允许提交!'});
        return;
      }
      
      if (!$scope.description.device_name_cn) {
        $scope.$emit('notification', {type: 'danger', message: '请填写device_name_cn'});
        return;
      }
      if (!$scope.description.device_type) {
        $scope.$emit('notification', {type: 'danger', message: '请填写device_type'});
        return;
      }
      if (!$scope.description.protocol_type) {
        $scope.$emit('notification', {type: 'danger', message: '请填写protocol_type'});
        return;
      }
      if($scope.deviceTypeSub.typeEn){
        for (let index = 0; index < $scope.deviceTypeList.length; index++) {
          const element_device_type = $scope.deviceTypeList[index];
          if(element_device_type.typeEn==$scope.deviceTypeSub.typeEn){
            $scope.deviceTypeSub.typeCn = element_device_type.typeCn;
            $scope.description.device_type_sub = $scope.deviceTypeSub;
            break;
          }
        }
      }
  
      
      if($scope.myEndpoints.length!=0){
        $scope.myEndpoints.forEach(element_myEndpoints => {
          if(element_myEndpoints.key){
            if(isNaN(element_myEndpoints.key)){
              $scope.$emit('notification', {type: 'danger', message: 'endpoint_id必须是数值'});
              return;
            }
          }else{
            $scope.$emit('notification', {type: 'danger', message: 'endpoint_id不能为空'});
            return;
          }
          if(!element_myEndpoints.value){
            $scope.$emit('notification', {type: 'danger', message: 'value_name不能为空'});
            return;
          }
          let findFlag = false;
          $scope.description.endpoints.forEach(element_endpoints => {
            
            if(element_myEndpoints.key==element_endpoints.endpoint_id){
              element_endpoints.value_list.push({
                value_name:element_myEndpoints.value
              })
              findFlag = true;
              return true;
            }
          });
          if(!findFlag){
            $scope.description.endpoints.push({
              endpoint_id:element_myEndpoints.key,
              value_list:[{
                value_name:element_myEndpoints.value
              }]
            })
          }
        });
      }
      console.log($scope.myEndpoints);
      console.log($scope.description.endpoints);
      let files = [];
      if($scope.uploadFiles.device_icon){
        if(isChinese($scope.uploadFiles.device_icon.name)){
          $scope.$emit('notification', {type: 'danger', message: 'device_icon图片名不能包含中文'});
          return;
        }
        let nickName = "description_icon"+$scope.getTime()+"_"+$scope.uploadFiles.device_icon.name;
        $scope.description.device_icon = $scope.domain+"/" +nickName;
        $scope.uploadFiles.device_icon.nickName = nickName;
        files.push($scope.uploadFiles.device_icon);
      }
      if($scope.uploadFiles.offline_icon){
        if(isChinese($scope.uploadFiles.offline_icon.name)){
          $scope.$emit('notification', {type: 'danger', message: 'offline_icon图片名不能包含中文'});
          return;
        }
        let nickName = "description_icon"+$scope.getTime()+"_"+$scope.uploadFiles.offline_icon.name;
        $scope.description.offline_icon = $scope.domain+"/" +nickName;
        $scope.uploadFiles.offline_icon.nickName = nickName;
        files.push($scope.uploadFiles.offline_icon);
      }
      if($scope.uploadFiles.device_reset_diagram){
        if(isChinese($scope.uploadFiles.device_reset_diagram.name)){
          $scope.$emit('notification', {type: 'danger', message: 'device_reset_diagram图片名不能包含中文'});
          return;
        }
        let nickName = "description_icon"+$scope.getTime()+"_"+$scope.uploadFiles.device_reset_diagram.name;
        $scope.description.device_reset_diagram = $scope.domain+"/" +nickName;
        $scope.uploadFiles.device_reset_diagram.nickName = nickName;
        files.push($scope.uploadFiles.device_reset_diagram);
      }

      let finishUploadCallBack = function(){
        $http({
          method: 'POST',
          url: '/web/description/upload',
          data: {data:$scope.description}
        }).then(function (response) {
            if (response.data.code == 0) {
              $scope.$emit('notification', {type: 'success', message: '上传成功，2秒后跳回智家描述表管理页'});
              $timeout(function () {
                $state.go("main.description")
              }, 2000)
            } else {
              $scope.$emit('notification', {type: 'danger', message: response.data.msg});
            }
          }, function (err) {
            $scope.$emit('notification', {type: 'danger', message: '网络错误,提交失败'});
          });
      }
      if(files.length>0){
        for (let index = 0; index < files.length; index++) {
          const element_files = files[index];
          if(index==files.length-1){
            localUploadFile(element_files,element_files.nickName,finishUploadCallBack);
            return;
          }else{
            localUploadFile(element_files,element_files.nickName);
          }
        }
      }else{
        finishUploadCallBack();
      }
    }

    var localUploadFile = function(file,nickName,callback){
      console.log("localUploadFile");
      $scope.upload = Upload.upload({
        method: 'POST',
        url: '/web/upgrade/uploadFileToServer',
        data: {filesDesc:[{
          oldName:file.name,
          newName:nickName
        }]},
        file: [file]
      }).progress(function (evt) {
          // var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
          // $('#progress')[0].style.width = progressPercentage + '%';
      }).success(function (data, status, headers, config) {
          if(data.code==0){
            if(callback){
              callback();
            }
          }else{
            if(data.msg){
              $scope.$emit('notification', {type: 'danger', message: data.msg});
            }else{
              $scope.$emit('notification', {type: 'danger', message: "上传失败"});
            }
            $timeout(function () {
              $('#progress')[0].style.width = 0 + '%';
            }, 500)
            
          }
      }).error(function (data, status, headers, config) {
          $scope.$emit('notification', {type: 'danger', message:"上传中断"});
          //$state.reload()
      })
    }

    $scope.getTime = function(file){
      return Date.now();
    }
    var isChinese = function(temp){
      if(/.*[\u4e00-\u9fa5]+.*$/.test(temp)) {
            return true;
          } 
          return false;
    }
  }

]);