/**
 * Created by Administrator on 2017/7/4.
 */


'use strict';
angular.module('controllers').controller('speekerCtr', ['$scope', 'Upload', '$http', '$window', '$timeout',
  function ($scope, Upload, $http, $window, $timeout) {

    $scope.device = {
      family: [],
      mac: ''
    };

    var loadTable = function (reload) {
      
      if (reload) {
        $('#table2').bootstrapTable('refresh');
      } else {
        $('#table2').bootstrapTable({
          method : 'get',
          url : "/web/speeker/getAll",//请求路径
          striped : true, //是否显示行间隔色
          pagination : true,//是否分页
          sidePagination : 'server',//server:服务器端分页|client：前端分页
          pageSize : 10,//单页记录数
          pageList : [ 5, 10, 20, 30 ],//可选择单页记录数
          showRefresh : true,//刷新按钮
          cache: false,
          search: true,
          showColumns: 'true',
          showExport: true,//显示导出按钮
          exportDataType: 'all',//导出类型
          queryParams : function(params) {//上传服务器的参数
            console.log(params);
            var temp = {//如果是在服务器端实现分页，limit、offset这两个参数是必须的
              limit : params.limit, // 每页显示数量
              page : (params.offset / params.limit) + 1, //当前页码 
              searchName : $('.search input').val(),
              order : params.order, 
              sort : params.sort,
            };
            return temp;
          },
          columns: [ {
            title: '中文命令词',
            align: 'center',
            sortable: true,
            field: 'nameCn'
          }, {
            title: '英文命令词',
            align: 'center',
            sortable: true,
            field: 'nameEn'
          },{
            title: '更新日期',
            align: 'center',
            field: 'updatedAt',
            formatter: function (value, row, index) {
              return moment(value).format('YYYY-MM-DD HH:mm')
            }
          },
            {
              title: '操作',
              align: 'center',
              formatter: function (value, row, index) {
                row = angular.toJson(row);
                return '<button  class=\'btn btn-danger btn-xs\' onclick=\'angular.element(this).scope().showDeleteModal(' + row + ')\'>删除</button>';
              }
            }
          ]
        });
      }
    };

    $scope.showDeleteModal = function (data) {
      $scope.toDelData =data;
      console.log(data);
      $timeout(function () {
        $('#deleteItemWin').modal('show')
      }, 10);
    };
    $scope.toDelete = function(){
      $http({
        method: 'POST',
        url: '/web/speeker/deleteOne',
        data:$scope.toDelData
      }).then(function (response) {
        $timeout(function () {
          $('#deleteItemWin').modal('hide')
        }, 10);
        if (response.data.code == 0) {
          $scope.$emit('notification', {type: 'success', message: '删除成功'});
          $scope.deviceInit(true);
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    }

    $scope.deviceInit = function (reload) {
      loadTable(reload)
    };

    $scope.openChildrenModal = function (children) {
      $scope.device.family = children || [];
      $timeout(function () {
        $('#childrenModal').modal('show');
      }, 10);
    };

    $scope.resetDevice = function (mac) {
      $scope.device.mac = mac;
      $timeout(function () {
        $('#resetModal').modal('show')
      }, 10);

    };
    $scope.speeker = {
      nameCn: '',
      nameEn:'',
    };
    $scope.addSpeeker = function () {
      if(!$scope.speeker.nameCn){
        $scope.$emit('notification', {type: 'danger', message: '请输入中文命令词'});
        return;
      }
      if(!$scope.speeker.nameEn){
        $scope.$emit('notification', {type: 'danger', message: '请输入英文命令词'});
        return;
      }
      if (/[']/.test($scope.speeker.nameCn)
      ||/[']/.test($scope.speeker.nameEn)) {
        $scope.$emit('notification', {type: 'danger', message: '内容包含英文的单引号，不允许提交!'});
        return;
      }
      $http({
        method: 'POST',
        url: '/web/speeker/upload',
        data: $scope.speeker
      }).then(function (response) {
        $('#resetModal').modal('hide');
        if (response.data.code == 0) {
          $scope.deviceInit(true);
          $scope.$emit('notification', {type: 'success', message: '添加成功'});
          $scope.speeker.nameCn = '';
          $scope.speeker.nameEn = '';
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });

    };

  }
]);