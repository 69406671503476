/**
 * Created by Administrator on 2017/7/4.
 */


'use strict';
angular.module('controllers').controller('deviceCtr', ['$scope', 'Upload', '$http', '$window', '$timeout',
  function ($scope, Upload, $http, $window, $timeout) {

    $scope.device = {
      family: [],
      mac: ''
    };

    var loadTable = function (reload) {
      
      if (reload) {
        $('#table2').bootstrapTable('refresh');
      } else {
        $('#table2').bootstrapTable({
          method : 'get',
          url : "/web/gateway/getAll",//请求路径
          striped : true, //是否显示行间隔色
          pagination : true,//是否分页
          sidePagination : 'server',//server:服务器端分页|client：前端分页
          pageSize : 10,//单页记录数
          pageList : [ 5, 10, 20, 30 ],//可选择单页记录数
          showRefresh : true,//刷新按钮
          cache: false,
          search: true,
          showColumns: 'true',
          showExport: true,//显示导出按钮
          exportDataType: 'all',//导出类型
          queryParams : function(params) {//上传服务器的参数
            console.log(params);
            var temp = {//如果是在服务器端实现分页，limit、offset这两个参数是必须的
              limit : params.limit, // 每页显示数量
              page : (params.offset / params.limit) + 1, //当前页码 
              searchName : $('.search input').val(),
              order : params.order, 
              sort : params.sort,
            };
            return temp;
          },
          columns: [{
            title: '网关类型',
            align: 'center',
            field: 'type',
            sortable: true,
            formatter: function (value, row, index) {
              return value == 1 ? '轻型网关' : value == 2 ? '智家网关' : value == 3 ? '安卓网关-ZigBee版' : '安卓网关-Z-Wave版';
            }
          }, {
            title: '网关mac码',
            align: 'center',
            sortable: true,
            field: 'mac'
          }, {
            title: '数字码',
            align: 'center',
            sortable: true,
            field: 'code'
          }, {
            title: '激活状态',
            align: 'center',
            field: 'activate',
            sortable: true,
            formatter: function (value, row, index) {
              return value ? '已激活' : '未激活';
            }
          },{
            title: '绑定的家庭',
            align: 'center',
            field: 'familyName',
            formatter: function (value, row, index) {
              return value ||'无';
            }
          },{
            title: '家庭管理员',
            align: 'center',
            field: 'familyAdmin',
            formatter: function (value, row, index) {
              return value ||'无';
            }
          },
            {
              title: '操作',
              align: 'center',
              formatter: function (value, row, index) {
                return '<button  class=\'btn btn-default btn-xs\' onclick=\'angular.element(this).scope().resetDevice("' + row.mac + '")\'><span class=\'glyphicon glyphicon-pencil\'></span>&nbsp;重置网关</button>';
              }
            }
          ]
        });
      }
    };

    //上传配置文件
    $scope.uploadFiles = function (file) {
      if (!file[0]) {
        return;
      }
      Upload.upload({
        url: '/web/gateway/uploadFile', method: 'POST', data: {file: file}
      }).then(function (resp) {
        if (resp.data.code != 0) {
          $scope.$emit('notification', {type: 'danger', message: resp.data.msg});
        } else {
          $scope.deviceInit(true);
          $scope.$emit('notification', {type: 'success', message: '成功导入' + resp.data.count + '条数据'});
        }
      }, function (resp) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };

    $scope.deviceInit = function (reload) {
      loadTable(reload)
    };

    $scope.openChildrenModal = function (children) {
      $scope.device.family = children || [];
      $timeout(function () {
        $('#childrenModal').modal('show');
      }, 10);
    };

    $scope.resetDevice = function (mac) {
      $scope.device.mac = mac;
      $timeout(function () {
        $('#resetModal').modal('show')
      }, 10);

    };

    $scope.toDoReset = function () {
      $http({
        method: 'POST',
        url: '/web/gateway/doReset',
        data: {mac: $scope.device.mac}
      }).then(function (response) {
        $('#resetModal').modal('hide');
        if (response.data.code == 0) {
          $scope.deviceInit(true);
          $scope.$emit('notification', {type: 'success', message: '重置成功'});
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    }
    $scope.gateway = {
      mac: '',
      sn:'',
    };
    $scope.addDevice = function () {
      if(!$scope.gateway.mac){
        $scope.$emit('notification', {type: 'danger', message: '请输入MAC'});
        return;
      }
      if(!$scope.gateway.sn){
        $scope.$emit('notification', {type: 'danger', message: '请输入SN'});
        return;
      }
      if (/[']/.test($scope.gateway.mac)
      ||/[']/.test($scope.gateway.sn)) {
        $scope.$emit('notification', {type: 'danger', message: '内容包含英文的单引号，不允许提交!'});
        return;
      }
      $http({
        method: 'POST',
        url: '/web/gateway/addGateway',
        data: $scope.gateway
      }).then(function (response) {
        $('#resetModal').modal('hide');
        if (response.data.code == 0) {
          $scope.deviceInit(true);
          $scope.$emit('notification', {type: 'success', message: '添加成功'});
          $scope.gateway.mac = '';
          $scope.gateway.sn = '';
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });

    };

  }
]);